import { View, TouchableOpacity } from "react-native";
import makeComponent from "./makeComponent";
import * as React from "react";

export default makeComponent(
  ({ onPress, children, ...props }) => {
    return (
      <TouchableOpacity {...props} onPress={onPress}>
        {children}
      </TouchableOpacity>
    );
  },
  { acceptStyles: true }
);
