import * as React from "react";
import { createAppContainer } from "react-navigation";
import { AsyncStorage, View } from "react-native";
import * as Font from "expo-font";

const persistenceKey = "app/location";

//AsyncStorage.removeItem(persistenceKey);

const persistNavigationState = async navState => {
  try {
    await AsyncStorage.setItem(persistenceKey, JSON.stringify(navState));
  } catch (err) {
    console.error(err);
  }
};
const loadNavigationState = async () => {
  const jsonString = await AsyncStorage.getItem(persistenceKey);
  return JSON.parse(jsonString);
};

export default (scope, element) => {
  const [fontLoaded, setFontLoad] = React.useState(false);
  const App = createAppContainer(scope.eval(element.children[0]));
  const props = scripts.evalProps(scope, element);

  React.useEffect(() => {
    Font.loadAsync({
      "Montserrat-Bold": require("../assets/fonts/montserrat.bold.ttf"),
      "Montserrat-Light": require("../assets/fonts/Montserrat-Light.ttf"),
      "Montserrat-SemiBold": require("../assets/fonts/Montserrat-SemiBold.ttf"),
      Montserrat: require("../assets/fonts/montserrat.regular.ttf"),
      "PlayfairDisplay-Black": require("../assets/fonts/PlayfairDisplay-Black.ttf"),
      "PlayfairDisplaySC-Regular": require("../assets/fonts/PlayfairDisplaySC-Regular.ttf"),
      "PlayfairDisplay-Bold": require("../assets/fonts/PlayfairDisplay-Bold.ttf")
    }).then(() => setFontLoad(true));
  }, []);

  if (!fontLoaded) {
    return null;
  }

  if (!__DEV__) {
    return (
      <App
        ref={navigatorRef => {
          if (props.onNavigation) {
            props.onNavigation(navigatorRef);
          }
        }}
      />
    );
  }

  return (
    <App
      ref={navigatorRef => {
        if (props.onNavigation) {
          props.onNavigation(navigatorRef);
        }
      }}
      persistNavigationState={persistNavigationState}
      loadNavigationState={loadNavigationState}
    />
  );
};
