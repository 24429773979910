import invariant from "invariant";
import * as React from "react";
import { evaluateExpression } from "./evaluateExpression";
import evalPropsAndChildren from "./scripts/evalPropsAndChildren";
import objectHasStream from "./scripts/objectHasStream";
import combineObject from "./scripts/combineObject";
import isStream from "./scripts/isStream";
import ObserveProps from "./scripts/ObservableProps";

const makeCustomComponent = (scope, assign) => {
  const result = (childScope, element) => {
    const Component = () => {
      return React.useMemo(() => {
        const { props, children } = evalPropsAndChildren(
          true,
          false,
          element,
          childScope
        );

        let combined = { ...props, children };

        if (objectHasStream(combined)) {
          combined = combineObject(combined);
        }

        return scope.branch(new Map([["props", combined]])).eval(assign.value);
      }, []);
    };

    Component.displayName = assign.name;

    return <Component />;
  };

  result.scope = scope;
  result.assign = assign;

  return result;
};

const RenderChild = ({ children }) => {
  return children;
};

const fragment = element => {
  if (Array.isArray(element)) {
    if (element.length === 0) {
      return null;
    }

    return React.createElement(React.Fragment, {}, element.map(fragment));
  }

  if (isStream(element)) {
    return (
      <ObserveProps
        value={element.map(el => ({ children: el }))}
        Component={RenderChild}
      />
    );
  }

  return element;
};

const evalChildren = (scope, children) => {
  return (
    <>
      {children.map((child, index) => {
        const value = scope.eval(child);

        if (isStream(value)) {
          return (
            <ObserveProps
              key={child.id}
              value={value.map(el => ({ children: el }))}
              Component={RenderChild}
            />
          );
        }

        return React.createElement(React.Fragment, { key: index }, value);
      })}
    </>
  );
};

export class Scope {
  constructor(public members: Map<string, any>, public parent?: Scope) {}

  get(name: string, location?: any) {
    if (this.members.has(name)) {
      return this.members.get(name);
    }

    if (this.parent != null) {
      return this.parent.get(name, location);
    }

    throw new Error(
      `no scope member ${name} at ${location && location.loc.start.line}`
    );
  }

  branch(members: Map<string, any>) {
    return new Scope(members, this);
  }

  letChildren = (children: any[]) => {
    const firstAssignment = children.findIndex(
      child => child.type === "Assign" || child.type === "Component"
    );

    if (firstAssignment !== -1) {
      invariant(firstAssignment === 1, "Let can only have one child");
      invariant(firstAssignment !== 0, "element must have a child");

      const assignments = children.slice(firstAssignment);

      invariant(
        assignments.every(
          assign => assign.type === "Assign" || assign.type === "Component"
        ),
        "expected all nodes after first assignment to be assignment"
      );

      const locals = new Map();
      const localScope = this.branch(locals);

      assignments.forEach(assign => {
        locals.set(
          assign.name,
          assign.type === "Component"
            ? makeCustomComponent(localScope, assign)
            : localScope.eval(assign.value)
        );
      });

      return localScope.eval(children[0]);
    }

    invariant(children.length === 1, "Let can only have one child");

    return this.eval(children[0]);
  };

  evalChildren = (children: any[]) => {
    const firstAssignment = children.findIndex(
      child => child.type === "Assign" || child.type === "Component"
    );

    if (firstAssignment !== -1) {
      invariant(firstAssignment !== 0, "element must have a child");

      const elements = children.slice(0, firstAssignment);
      const assignments = children.slice(firstAssignment);

      const wrong = assignments.find(
        assign => !(assign.type === "Assign" || assign.type === "Component")
      );
      invariant(
        wrong == null,
        `expected all nodes after first assignment to be assignment at ${wrong &&
          wrong.loc &&
          wrong.loc.start.line}`
      );

      const locals = new Map();
      const localScope = this.branch(locals);

      assignments.forEach(assign => {
        locals.set(
          assign.name,
          assign.type === "Component"
            ? makeCustomComponent(localScope, assign)
            : localScope.eval(assign.value)
        );
      });

      return evalChildren(localScope, elements);
    }

    return evalChildren(this, children);
  };

  eval = (node: any) => {
    return render(this, node);
  };
}

export const render = (scope: Scope, node: any) => {
  if (node.type === "StringLiteral") {
    return node.value;
  }

  if (node.type === "Expression") {
    return evaluateExpression(scope, node.value);
  }

  const value = scope.get(node.name.name);

  return renderComponent(scope, value, node);
};

const RenderDynamicComponent = ({ scope, node, value }) => {
  return renderComponent(scope, value, node);
};

const renderComponent = (scope: Scope, value: any, node: any) => {
  invariant(
    value instanceof Function || isStream(value),
    `expected %s to be function or stream`,
    node.name.name
  );

  if (isStream(value)) {
    return (
      <ObserveProps
        Component={RenderDynamicComponent}
        value={value.map(value => ({ scope, node, value }))}
      />
    );
  }

  return value(scope, node);
};
