import { makeForm, makeState } from "./form-control";
import xs from "xstream";
import { AsyncStorage } from "react-native";

const createLocalBoolean = (key, strict) => {
  const fromStorage$ = xs
    .fromPromise(AsyncStorage.getItem(key))
    .map(value => (value === null ? true : JSON.parse(value)));

  return {
    state: makeState(fromStorage$, strict),
    getErrors: () => []
  };
};

export default ({ strict, scope }) => {
  const navigation$ = scope.props.map(props => props.navigation);
  const saveClicked$ = scope["save-button-clicked"].event;

  const { fields, state, submit } = makeForm(saveClicked$, {
    helperNotifications: createLocalBoolean("helperNotifications", strict),
    specialOffers: createLocalBoolean("specialOffers", strict)
  });

  strict(
    submit.compose(
      scripts.sample(xs.combine(state, navigation$), ([state, navigation]) => {
        AsyncStorage.setItem(
          "helperNotifications",
          JSON.stringify(state.helperNotifications)
        );
        AsyncStorage.setItem(
          "specialOffers",
          JSON.stringify(state.specialOffers)
        );

        navigation.pop();
      })
    )
  );

  return {
    ...fields
  };
};
